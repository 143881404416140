.ig-header-banner-main {
    color: #fff;
    display: flex;
    align-items: center;
}

.ig_banner_heading {
    color: #FFFFFF;
    font-family: 'Roboto';
    font-size: 32px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.01em;
    padding: 0px 0px 32px 0;
}

.ig-header-banner-main h1 {
    font-family: 'Bauhaus';
    font-style: normal;
    font-weight: 400;
    font-size: 72px;
    line-height: 86px;
    color: #FFFFFF;
    margin: 0px;
    padding-top: 120px;

}

.ig-header-banner-main p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    padding-bottom: 25px;
}

.ig-header-banner-img {
    width: 100%;
    height: 100%;
    object-fit: none;
}

.ig-header-banner-right-container {
    margin-top: 60px;
}

@media (min-width: 1024px) and (max-width: 1365px) {
    .ig-header-banner-main {
        padding: 0 30px;
    }

    .ig-header-banner-img {
        width: 100%;
        height: 100%;
        object-fit: none;
    }
}

@media (min-width: 1024px) and (max-width: 1279px) {
    .ig-header-banner-img {
        width: 100%;
        height: 100%;
        object-fit: inherit;
    }
}

@media (min-width: 1450px) and (max-width: 1920px) {
    .ig-header-banner-img {
        width: 100%;
        height: 100%;
        object-fit: inherit;
    }
}

/* new style for banner section */

.ig_bannernewcontainer-wrapper {
    height: 566px;
    /* height: 175px; */
}

.ig_bannernewcontainer {
    width: 1160px;
    margin: 0 auto;
    /* padding: 0px 50px; */
}

.ig_bannernewsection {
    width: 100%;
    padding: 45px 0 0px;
    position: relative;

}

.ig_bannernewbannerleftcolum {
    width: 42%;
    display: inline-block;
    /* height: 460px; */
    height: 411px;
}

.ig_bannernewbannerleftcolum>img {
    width: 100%;
    height: auto;
    display: inline-block;
    object-fit: cover;
    object-position: left;
}

.ig_bannernewbannerrightcolum {
    width: 58%;
    display: inline-block;
    /* height: 460px; */
    height: 411px;
    border-radius: 12px;
    overflow: hidden;
}

.ig_bannernewbannerrightcolum>img {
    width: 100%;
    height: 100%;
    /* display: inline-block; */
    object-fit: cover;
    border-radius: 12px;
}


@media (min-width: 1024px) and (max-width: 1279px) {
    .ig_bannernewcontainer {
        width: 986px;
        margin: 0 auto;
    }

}

@media (min-width: 1024px) and (max-width: 1366px) {
    .ig_bannernewbannerleftcolum {
        height: 360px;
    }

    .ig_bannernewbannerrightcolum {
        height: 360px;
    }

    .ig_bannernewsection {
        padding: 49px 0 0px;
        position: relative;
    }

    /* .ig_bannernewcontainer-wrapper {
        height: 175px;
    } */
}

.ig_banner_image {
    width: 94%;
    height: 60vh;
    border-radius: 10px;
    display: flex;
    margin: 0 auto;
}

.carousel_button_left {
    position: absolute;
    top: 60%;
    left: -28px;
    transform: translateY(-50%);
    color: #000090;
    border: none;
    font-size: 14px;
    height: 27px;
    width: 27px;
    border-radius: 50%;
    z-index: 1;
    /* Ensure buttons are above images */
}

.carousel_button_right {
    position: absolute;
    top: 60%;
    right: -28px;
    transform: translateY(-50%);
    color: #000090;
    border: none;
    font-size: 14px;
    height: 27px;
    width: 27px;
    border-radius: 50%;
}

.rotate_180deg {
    rotate: 180deg;
}

.carousel_container {
    position: unset;
    width: 100%;
    overflow: hidden;
    border-radius: 10px;

}

.carousel_inner {
    display: flex;
    transition: transform 0.5s ease-in-out;
    /* Smooth sliding */
}

.carousel__image {
    height: 412px;
    width: 100%;
    /* object-fit: contain; */
    flex-shrink: 0;
    border-radius: 10px;
    /* padding: 0 40px; */
}